import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactusTopBackground from '../../../assets/ContactusTopBackground.png';
import PhoneIcon from '../../../assets/PhoneIcon.svg';
import HeadphoneIcon from '../../../assets/HeadphoneIcon.svg';
import MailIcon from '../../../assets/MailIcon.svg';
import LocationIcon from '../../../assets/LocationIcon.svg';
import './Contactus.scss';

const Contactus = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // global duration for all animations
      once: true, // whether animation should happen only once while scrolling down
    });
  }, []);

  return (
    <Container fluid className="Container">
      <img src={ContactusTopBackground} alt="bg" style={{ width: '100%', marginTop: '0px' }} data-aos="fade-up" data-aos-duration="1000" />
      <h1 data-aos="fade-up" data-aos-duration="1000">Contact Us</h1>
      <Row>
        <Col xs={12} lg={6} className="ContactusContainer" data-aos="fade-right" data-aos-duration="1000">
          <div className="Contactus">
            <div className="SalesNumber">
              <div className="Icon">
                <img src={PhoneIcon} alt="phoneicon" />
              </div>
              <div className="Number">
                <p>+91-9999999999 | +91-9999999999</p>
                <h6>Sales</h6>
              </div>
            </div>
            <div className="CustomerNumber">
              <div className="Icon">
                <img src={HeadphoneIcon} alt="HeadphoneIcon" />
              </div>
              <div className="Number">
                <p>+91-9999999999 | +91-9999999999</p>
                <h6>Customer Support</h6>
              </div>
            </div>
            <div className="Email">
              <div className="Icon">
                <img src={MailIcon} alt="MailIcon" />
              </div>
              <div className="Number">
                <p>sales@sara.co.uk</p>
              </div>
            </div>
            <div className="Address">
              <div className="Icon">
                <img src={LocationIcon} alt="LocationIcon" />
              </div>
              <div className="Number">
                <h6>SARA Library Management System</h6>
                <p>Suite No. 3.4, Litchurch Plaza, Litchurch Lane, Derby, Derbyshire, DE24 8AA, United Kingdom</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} className="ContactusForm" data-aos="fade-left" data-aos-duration="1000">
          <h1>Enquiry Form</h1>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="name">
                  <Form.Control type="text" placeholder="Your Name" />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="destination">
                  <Form.Control type="text" placeholder="Your Destination" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="organization">
                  <Form.Control type="text" placeholder="Your Organization" />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="city">
                  <Form.Control type="text" placeholder="Your City" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="email">
                  <Form.Control type="email" placeholder="Your Email" />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="phoneNumber">
                  <Form.Control type="text" placeholder="Your Phone Number" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="comments">
                  <Form.Control as="textarea" rows={4} placeholder="Write your comments here" />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contactus;
