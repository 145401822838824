import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  return (
    <Container className="privacy-policy">
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p>
            M2R2 values and respects the privacy of its stakeholders. It is our policy to comply with all applicable laws and regulations regarding the collection, use, and sharing of any personal information we may gather about you, including across our website, <a href="http://m2r2.co.uk/">http://m2r2.co.uk/</a>, and other sites we own and operate. This privacy policy outlines our commitment to safeguarding your data and protecting your personal information.
          </p>

          <h3>Personal Information</h3>
          <p>
            Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service.
          </p>

          <h3>Third-Party Links</h3>
          <p>
            In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site.
          </p>

          <p><strong>Effective Date:</strong> 23 October 2023</p>
          <p><strong>Last Updated:</strong> 25 October 2023</p>

          <h2>Information We Collect</h2>
          <p>
            Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information.
          </p>

          <h3>Personal Information Collection</h3>
          <p>
            During service inquiries, talent recruitment, client consultations, or when you contact us, we may collect and request personal information which may include, but is not limited to:
            <ul>
              <li>Name</li>
              <li>Email</li>
              <li>Phone/mobile number</li>
              <li>Professional histories</li>
              <li>Other relevant details related to our services</li>
            </ul>
          </p>

          <h3>Legitimate Reasons for Processing Your Personal Information</h3>
          <p>
            We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.
          </p>

          <h2>Collection and Use of Information</h2>
          <p>
            The collected data is primarily used to tailor our services, facilitate effective recruitment, and maintain transparent client relations. We also use the information for internal record-keeping and service improvement purposes.
          </p>

          <p>
            We may collect personal information from you when you do any of the following on our website:
            <ul>
              <li>Use a mobile device or web browser to access our content</li>
              <li>Contact us via email, social media, or on any similar technologies</li>
              <li>When you mention us on social media</li>
            </ul>
          </p>

          <p>
            We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:
            <ul>
              <li>To provide you with our platform's core features and services</li>
              <li>To contact and communicate with you</li>
              <li>To consider your employment application</li>
            </ul>
          </p>

          <h2>Security of Your Personal Information</h2>
          <p>
            When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification. Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure and no one can guarantee absolute data security.
          </p>


          <h2>Children’s Privacy</h2>
          <p>
            We do not aim any of our products or services directly at children under the age of 13 and we do not knowingly collect personal information about children under 13.
          </p>

          <h2>Disclosure of Personal Information to Third Parties</h2>
          <p>
            M2R2 does not sell or lease personal information. Nevertheless, there are specific scenarios where personal data may be shared:
            <ul>
              <li>With partner Original Equipment Manufacturers (OEMs) strictly for recruitment purposes</li>
              <li>As required by law, including in connection with any legal proceedings or in order to establish, exercise, or defend our legal rights</li>
              <li>To our employees, contractors, and/or related entities strictly for operational purposes</li>
              <li>With MailChimp, which we utilize to facilitate certain functions of our platform. As a result, personal data will be shared with MailChimp for operational and service delivery purposes</li>
            </ul>
          </p>

          <p>
            Outside these scenarios, M2R2 will not share personal information with any third parties. We commit to maintaining the trust you place in us and to safeguarding your personal data.
          </p>


          <h2>Changes to This Policy</h2>
          <p>
            At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy. If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
          </p>


          <h2>Your Rights and Controlling Your Personal Information</h2>
          <p>
            Your choice: By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us; however, if you do not, it may affect your use of our website or the products and/or services offered on or through it.
          </p>

          <p>
            Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.
          </p>

          <p>
            Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.
          </p>

          <p>
            Access: You may request details of the personal information that we hold about you. An administrative fee may be payable for the provision of such information. In certain circumstances, as set out in the Privacy Act 1988 (Cth), we may refuse to provide you with personal information that we hold about you.
          </p>


          <h2>Contact Us</h2>
          <p>
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@m2r2.co.uk
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
