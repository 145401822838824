import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import './CheckoutForm.scss';
import SARAlogo from '../../../../assets/SARAlogo.svg';
import Loadinggif from '../../../../assets/loadinggif.gif';
import cookieHandler from '../../../../cookieHandler.js';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const URL = process.env.REACT_APP_URL;

const LoadingModal = () => (
  <div className="loading-modal">
    <div className="loading-content">
      <img src={Loadinggif} alt="Loading..." />
    </div>
  </div>
);

const CheckoutForm = () => {
  const [processing, setProcessing] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  let [searchParams] = useSearchParams();
  const priceId = searchParams.get('priceId');
  const title = searchParams.get('title');
  const description = searchParams.get('description'); 
  const price = searchParams.get('price'); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    (async () => {
      const emailId = cookieHandler.getCookie('email');
      if (emailId) {
        await setEmail(emailId);
      }
    })()
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setShowLoading(true);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
          address: {
            line1: addressLine,
            country: 'GB',
          },
        },
      });

      if (error) {
        console.error('Payment method creation failed:', error);
        setError(error.message);
        setProcessing(false);
        setShowLoading(false);
        return;
      }

      // const response = await fetch('http://localhost:4000/create-subscription', {
      const response = await fetch(`${API_ENDPOINT}/create-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: priceId,
            paymentMethodId: paymentMethod.id,
            name: name,
            email: email,
            address: {
              country: 'GB',
              line1: addressLine,
            }
          }),
        });
    
        if (!response.ok) {
          throw new Error('Subscription creation failed');
        }
    
        const responseData = await response.json();
    
        console.log('Response Data:', responseData);
    
        if (responseData.requiresAction) {
          const clientSecret = responseData.clientSecret;
    
          const result = await stripe.confirmPayment({
            clientSecret: clientSecret,
            confirmParams: {
              return_url: 'https://lms.m2r2.co.uk/success'
            },
          });
    
          if (result.error) {
            console.error(result.error);
            setError(result.error.message);
          } else {
            window.location.href = 'https://lms.m2r2.co.uk/success';
          }
        } else {
          window.location.href = 'https://lms.m2r2.co.uk/success';
        }
      } catch (error) {
        setError(error.message);
        console.error('Subscription creation failed:', error.message);
      } finally {
        setProcessing(false);
        setShowLoading(false);
      }
    };

  return (
    <Container>
      <Row className='Container'>
        <Col className='LeftColumn'>
          <div className='LeftContainer'>
            <div className='Logo'>
              <img src={SARAlogo} alt="saralogo" width={150} />
            </div>
            <div className='Heading'>
              <h2>Subscription Summary</h2>
              <h5>Selected Plan : <span>{title}</span></h5>
              <h5>Plan Description : <span>{description}</span></h5>
              <h5>Plan price : <span>£ {price}</span></h5>
              <h5>GST : <span>£ 0</span></h5>
              <hr />
              <h5>Total : <span>£ {price}</span> </h5>
            </div>
          </div>
        </Col>
        <Col className='RightColumn'>
          <div className='checkout-form-container'>
            <h2>Subscription Payment Method</h2>
            <form onSubmit={handleSubmit}>
              <div className='input-group'>
                <h3>Contact information</h3>
                <p style={{border:"1px solid grey",padding:"3px 7px",borderRadius:"10px"}}>Email : {email}</p>
                 <input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Card Holder Name'
                  required
                />
                <input
                  type='text'
                  value={addressLine}
                  onChange={(e) => setAddressLine(e.target.value)}
                  placeholder='Full Address'
                  required
                />
              </div>
              <div className='card-element'>
                <CardElement />
                <br />
                <p style={{fontSize:"12px"}}>By providing your card information, you allow SARA Inc to charge your card for future payments in accordance with their terms.</p>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type='submit' className='subscribe-button' disabled={!stripe || processing}>
                {processing ? 'Processing...' : `Pay £${price}.00`}
              </button>
            </form>
            {showLoading && <LoadingModal />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutForm;
