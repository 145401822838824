import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import AuthHandler from "../Auth/AuthHandler";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  if (!AuthHandler.isUserLoggedIn()) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
