import React, { useState, useRef } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import './EmailVerification.scss';

const token = 'FV14DwYx81XpuakIEncdTAUz3O5Y16qP';

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);

  let [searchParams, ] = useSearchParams();
  const email = searchParams.get('email');

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    
    // Move to the next input field if the current one is filled
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleVerification = async () => {
    const combinedOtp = otp.join('');
    try {
      const response = await fetch('https://sara-api.m2r2.co.uk/api/auth/verifyEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ email, otp: combinedOtp }),
      });

      const responseText = await response.text();
      if (!response.ok) {
        throw new Error(responseText || 'OTP verification failed');
      }

      Swal.fire({
        icon: 'success',
        title: 'Verification Successful',
        text: 'Your email has been verified successfully.',
      }).then(() => {
        navigate('/signin');
      });

    } catch (error) {
      console.error('OTP verification error:', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Verification Failed',
        text: JSON.parse(error.message).message,
      });
    }
  };

  return (
    <Container className="EmailVerificationContainer">
      <Row>
        <Col xs={12} className="EmailVerificationHeader">
          <h2>Enter OTP sent to {new URLSearchParams(location.search).get('email')}</h2>
          <p>Please enter the OTP sent to your email to verify your account.</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div className="verification-form">
            <Form onSubmit={(e) => { e.preventDefault(); handleVerification(); }}>
              <Form.Group controlId="formBasicOtp">
                <div className="otp-inputs">
                  {otp.map((digit, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      ref={el => inputRefs.current[index] = el}
                      className="otp-input"
                      required
                    />
                  ))}
                </div>
              </Form.Group>
              <Button variant="primary" type="submit" className="verify-button">
                Verify
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerification;
