// cookieHandler.js
class CookieHandler {
    constructor() {
        if (!CookieHandler.instance) {
            this.tokenKey = 'authToken';
            this.refreshTokenKey = 'refreshToken';
            CookieHandler.instance = this;
          }
      
          return CookieHandler.instance;
    }
  
    setCookie(name, value, expires) {
      document.cookie = `${name}=${value}; path=/; max-age=${expires}; path=/;`;
    }
  
    getCookie(name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      return match ? match[2] : null;
    }
  
    deleteCookie(name) {
      document.cookie = `${name}=; max-age=-99999999;`;
    }
  
    setTokens({  token, expiresIn, refreshToken, refreshExpiresIn, accountNumber, accountName, email, roles, firstName, lastName}) {
      this.setCookie('token', token, expiresIn);
      this.setCookie('refreshToken', refreshToken, refreshExpiresIn);
      this.setCookie('accountNumber', accountNumber);
      this.setCookie('accountName', accountName);
      this.setCookie('email', email);
      this.setCookie('roles', roles);
      this.setCookie('firstName', firstName);
      this.setCookie('lastName', lastName);
      this.monitorTokenExpiry(expiresIn);
    }
  
    // async refreshToken() {
    //   const refreshToken = this.getCookie('refreshToken');
    //   if (!refreshToken) {
    //     throw new Error('No refresh token available');
    //   }
  
    //   // Validate refresh token expiry
    //   const refreshTokenExpiryDate = new Date(this.getCookie('refreshTokenExpiry'));
    //   if (refreshTokenExpiryDate <= new Date()) {
    //     throw new Error('Refresh token expired');
    //   }
  
    //   try {
    //     const newTokenData = await this.apiClient.refreshToken(refreshToken);
    //     this.setTokens(newTokenData);
    //   } catch (error) {
    //     console.error('Failed to refresh token', error);
    //     this.deleteCookie('token');
    //     this.deleteCookie('refreshToken');
    //     throw error;
    //   }
    // }
  
    monitorTokenExpiry(tokenExpiryDays) {
      const tokenExpiryMilliseconds = tokenExpiryDays * 864e5;
      setTimeout(async () => {
        try {
          await this.refreshToken();
        } catch (error) {
          console.error('Token refresh failed', error);
        }
      }, tokenExpiryMilliseconds);
    }
  }
  const instance = new CookieHandler();
  Object.freeze(instance);
  
  export default instance;
  