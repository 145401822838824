import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import m2r2logo from '../../assets/m2r2logo.svg';
import InstagramIcon from '../../assets/InstagramIcon.svg';
import LinkedinIcon from '../../assets/LinkedinIcon.svg';
import TwitterIcon from '../../assets/TwitterIcon.svg';
import YoutubeIcon from '../../assets/YoutubeIcon.svg';
import Derbylocation from '../../assets/Derbylocation.png';
import Indialocation from '../../assets/Indialocation.png';
import RequestDemo from '../pages/RequestDemo/RequestDemo';
import './Footer.scss';

const Footer = () => {
  const [showRequestDemo, setShowRequestDemo] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000, // global duration for all animations
      once: true, // whether animation should happen only once while scrolling down
    });
  }, []);

  const handleDemoRequest = () => {
    setShowRequestDemo(true);
  };

  const handleCancelRequest = () => {
    setShowRequestDemo(false);
  };

  return (
    <Container fluid className="Container">
      <Row className='GetInTouchContainer' data-aos="fade-up" data-aos-duration="1000">
        <Col>
          <div className='GetInTouchText'>
            <h1>Get In Touch With Our Experts</h1>
            <p>Find out how your library's operations can be made more efficient using our SARA library management system. Get a demo right now!</p>
            <Button className='GetInTouchButton' onClick={handleDemoRequest}>Book a Demo</Button>
          </div>
        </Col>
      </Row>
      <Row className='Footer' data-aos="fade-up" data-aos-duration="1000">
        <Col>
          <Row className='Firstrow'>
            <Col xs={12} md={3}>
              <img src={m2r2logo} alt="m2r2logo" className="img-fluid" />
            </Col>
            <Col xs={12} md={9}>
              <Row>
                <Col xs={6} sm={2}>
                  <div className='Footermenu'>Home</div>
                </Col>
                <Col xs={6} sm={2}>
                  <div className='Footermenu'>Products</div>
                </Col>
                <Col xs={6} sm={2}>
                  <div className='Footermenu'>Services</div>
                </Col>
                <Col xs={6} sm={2}>
                  <div className='Footermenu'>Contact</div>
                </Col>
                <Col xs={12} sm={2}>
                  <div className='Footermenu'>Join us</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className='Secondrow'>
            <Col xs={12} md={6} data-aos="fade-right" data-aos-duration="1000">
              <div className='Location'>
                <h3>UK Office</h3>
                <p>Suite No. 3.4, Litchurch Plaza, Litchurch Lane, Derby, Derbyshire, DE24 8AA, United Kingdom</p>
                <div className='SocialIcons'>
                  <img src={InstagramIcon} alt="Instagram" />
                  <img src={LinkedinIcon} alt="LinkedIn" />
                  <img src={TwitterIcon} alt="Twitter" />
                  <img src={YoutubeIcon} alt="Youtube" />
                </div>
                <img src={Derbylocation} alt="derbylocation" className='LocationImage' />
              </div>
            </Col>
            <Col xs={12} md={6} data-aos="fade-left" data-aos-duration="1000">
              <div className='Location'>
                <h3>India Office</h3>
                <p>53/3, Celebrity Paradise Main St, Doddathoguru, Electronics City Phase 1, Bengaluru, KA 560100, India</p>
                <div className='SocialIcons'>
                  <img src={InstagramIcon} alt="Instagram" />
                  <img src={LinkedinIcon} alt="LinkedIn" />
                  <img src={TwitterIcon} alt="Twitter" />
                  <img src={YoutubeIcon} alt="Youtube" />
                </div>
                <img src={Indialocation} alt="indialocation" className='LocationImage' />
              </div>
            </Col>
          </Row>
          <hr />
          <Row className='Thirdrow' data-aos="fade-up" data-aos-duration="1000">
            <Col xs={12} md={9}>
              <div className='Footerpolicies'>
                <div><a href="/termsofuse">Terms of Use</a></div>
                <div><a href="/cookiepolicy">Cookie policy</a></div>
                <div><a href="/privacypolicy">Privacy policy</a></div>
                <div><a href="/itsecuritypolicies">IT Security Policies</a></div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <p className="text-md-right">@2024 M2R2. All Rights Reserved.</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {showRequestDemo && (
        <RequestDemo onCancelRequest={handleCancelRequest} />
      )}
    </Container>
  );
};

export default Footer;
