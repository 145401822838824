import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import cookieHandler from "../../../../cookieHandler";
// import { useNavigate } from 'react-router-dom';
import PointIcon from '../../../../assets/PointIcon.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SubscriptionSuccessPage.scss';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const SubscriptionSuccessPage = () => {
  // const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  updateSubscriptionStatus();
  useEffect(() => {
    setIsVisible(true);
  }, []);
   function updateSubscriptionStatus() {
    const emailId = cookieHandler.getCookie('email');
     fetch(`${API_ENDPOINT}/update-subscription-status`, {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify({ emailId: emailId}),
   });
 }
  // const handleGoToProduct = () => {
  //   navigate('beta.sara.m2r2.co.uk')
  // };

  return (
    <motion.div
      className={`success-page-container ${isVisible ? 'fade-in' : ''}`}
      initial={{ opacity: 0, y: -50 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.5 }}
    >
      <img src={PointIcon} alt="PointIcon" width={100} /><br /><br />
      <h2>Thank you for subscribing!</h2>
      <p>You have successfully subscribed to our product.</p>

      <a href="https://beta.sara.m2r2.co.uk" target="_blank" rel="noopener noreferrer">
        <Button variant="primary">
          Go to Product
        </Button>
      </a>
    </motion.div>
  );
};

export default SubscriptionSuccessPage;
