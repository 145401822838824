import Blog1 from '../assets/Blog1.png'
import Blog2 from '../assets/Blog2.png'
import Blog3 from '../assets/Blog3.png'

export const BlogData = [
    {
        id:1,
        date:"Mar 1",
        author:"Mark Tuchel",
        image:Blog1,
        title:"RFID in Library Management System",
        description:"Explore how RFID technology is transforming library operations, from efficient book tracking to streamlined, event notifications, and interactive self-checkout systems."
    },
    {
        id:2,
        date:"Mar 1",
        author:"Mark Tuchel",
        image:Blog2,
        title:"Enhancing Member Engagement in Libraries",
        description:"Description: Discover strategies to boost member engagement in libraries using personalized recommendations, event notifications, and interactive digital tools."
    },
    {
        id:3,
        date:"Mar 1",
        author:"Mark Tuchel",
        image:Blog3,
        title:"AI and Machine Learning in Library Cataloging",
        description:" Learn about the future of library cataloging with AI and machine learning technologies, empowering librarians to categorize and recommend resources effectively."
    },
]


export const Tags =[
    {id:1,tags:"Library"},
    {id:1,tags:"Cloud Computing"},
    {id:1,tags:"LMS"},
    {id:1,tags:"cloud"},
    {id:1,tags:"Libraries"},
    {id:1,tags:"FAQ"},
    {id:1,tags:"Library Automation"},
    {id:1,tags:"Patrons"},
    {id:1,tags:"Circulations"},
    {id:1,tags:"Physical Books"},
]