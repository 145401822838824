export const SubscriptionTableData = [
    {
        id:1,
        Plan:"Basic",
        lists:[
            
            {list:"Catalog Management"},
            {list:"CRUD operations with Branches, Floors and Zones"},
            {list:"Added own Shelves"},
            {list:"CRUD operations with Patrons"},
            {list:"CRUD operations with Books and Items"},
            {list:"Catologue management"},
            {list:"CRUD operations with Orders"},
        ]
    },
    {
        id:1,
        Plan:"Standard",
        lists:[
            
            {list:"Catalog Management"},
            {list:"CRUD operations with Branches, Floors and Zones"},
            {list:"Added own Shelves"},
            {list:"CRUD operations with Patrons"},
            {list:"CRUD operations with Books and Items"},
            {list:"Catologue management"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
        ]
    },
    {
        id:1,
        Plan:"Professional",
        lists:[
            
            {list:"Catalog Management"},
            {list:"CRUD operations with Branches, Floors and Zones"},
            {list:"Added own Shelves"},
            {list:"CRUD operations with Patrons"},
            {list:"CRUD operations with Books and Items"},
            {list:"Catologue management"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
        ]
    },
    {
        id:1,
        Plan:"Enterprise",
        lists:[
            
            {list:"Catalog Management"},
            {list:"CRUD operations with Branches, Floors and Zones"},
            {list:"Added own Shelves"},
            {list:"CRUD operations with Patrons"},
            {list:"CRUD operations with Books and Items"},
            {list:"Catologue management"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
            {list:"CRUD operations with Orders"},
        ]
    },

]