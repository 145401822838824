import React, { useEffect } from 'react';
import { Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BlogData } from '../../../constants/BlogData';
import { Tags } from '../../../constants/BlogData';
import './Blog.scss';

const Blog = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true,
    });
  }, []);

  return (
    <Container fluid className="Container">
      <Row className='TopBlogs'>
        <Col xs={12} md={8} data-aos="fade-up" data-aos-duration="1000">
          <h1>Blogs of SARA LMS</h1>
        </Col>
        <Col xs={12} md={4} data-aos="fade-left" data-aos-duration="1000">
          <Form>
            <Row className='align-items-center'>
              <Col xs={8} md={9}>
                <FormControl type='text' placeholder='Search' className='mr-sm-2' />
              </Col>
              <Col xs={4} md={3}>
                <Button variant='outline-success' type='submit'>Search</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className='DownBlogs'>
        <Col xs={12} md={4}>
          <div className='RecentPosts' data-aos="fade-up" data-aos-duration="1000">
            <h4>Recent Posts</h4>
            {BlogData.map((data) => (
              <div key={data.id} className='RecentPostsList'>
                <ul>
                  <li>{data.title}</li>
                  <hr />
                </ul>
              </div>
            ))}
          </div>
          <div className='Tags' data-aos="fade-up" data-aos-duration="1000">
            <h4>Tags</h4>
            {Tags.map((data) => (
              <p key={data.id}>{data.tags}</p>
            ))}
          </div>
        </Col>
        <Col xs={12} md={8}>
          <div className='Blogs'>
            {BlogData.map((data) => (
              <div key={data.id} className='Blog' data-aos="fade-up" data-aos-duration="1000">
                <div className='Left'>
                  <h6>{data.date}</h6>
                  <h5>{data.title}</h5>
                  <p>{data.description}</p>
                  <h6>by: {data.author}</h6>
                </div>
                <div className='Right'>
                  <img src={data.image} alt='blogimage' />
                </div>
                <hr />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Blog;
