export const ModulePoints =[
    {
        id:1,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Core Module",
        description:" This foundational module includes essential functionalities such as customising the library's name, description, logo, theme, address, and contact details. It also encompasses branch, floor, zone, shelf, book, item, patron, author, and publisher management, ensuring comprehensive control over every library facet."
    },
    {
        id:2,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Circulation Module",
        description:"Manages all circulation-related details, facilitating efficient lending, returns, and renewals."
    },
    {
        id:3,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Acquisitions Module",
        description:"Oversees funds and budgets, supplier management, and order management, including order placement, invoicing, receipt, and EDI transactions."
    },
    {
        id:4,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Inventory and Stock Module",
        description:"Provides detailed management of library inventory, ensuring accurate tracking of all library assets."
    },
    {
        id:5,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Reporting and Analytics Module",
        description:"Offers advanced reporting, customised reports, analytics, business intelligence, system audits, and user activity audits, empowering libraries with data-driven insights for strategic decision-making."
    },
    {
        id:6,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Monitoring Module",
        description:"Ensures optimal system performance through real-time activity monitoring, maintaining system health and integrity."
    },
    {
        id:7,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Integration Module",
        description:"Facilitates seamless integration with third-party services and databases, enhancing library services and resource accessibility."
    },
    {
        id:8,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Payment Module",
        description:"Incorporates Stripe API integration for efficient management and payment of loans, charges, and fines."
    },
    {
        id:9,
        icon:
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_87_933)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="#624BFF"/>
        </g>
        <defs>
        <clipPath id="clip0_87_933">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>,
        title:"Cash Handling",
        description:"Advanced cash handling features are also included, with further details available within the platform."
    },

];