import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import ProtectedRoute from "../../ProtectedRoute";

const stripePromise = loadStripe('pk_test_51PLo8zRv36BsH0g1MGyvllwuS6IJee5Ngbwx92hgS2zkkLjKRTdKJc1zOHUVsmbExaoKfU0974lpFoQnil8ZNP2G00DbBotCB1');

const SubscriptionConnection = ({ priceId, title, description, price }) => {
  return (
    <ProtectedRoute>
    <Elements stripe={stripePromise}>
      <CheckoutForm priceId={priceId} title={title} description={description} price={price} />
    </Elements>
    </ProtectedRoute>
  );
};

export default SubscriptionConnection;
