export const Essential =[
    {
        id:1,
        title:"Personal (£1/month)",
        description: "loreum ipsum",
        Price:1,
        points:[
            {
                point:"Catalog Management"
            },
            {
                point:"Member Management (up to 500 members)"
            },
            {
                point:"Check-in/Check-out"
            },
            {
                point:"Basic Reporting"
            },
            {
                point:"Ideal for : Small libraries or startups looking for essential library management tools. "
            }
        ],
        priceId:'price_1PM557Rv36BsH0g1R8VtCApE'
    },
    {
        id:2,
        title:"Home (£3/month)",
        description: "loreum ipsum",
        Price:3,
        points:[
            {
                point:"Unlimited Members"
            },
            {
                point:"Advanced Reporting"
            },
            {
                point:"Fine Management"
            },
            {
                point:"RFID Integration"
            },
            {
                point:" Ideal for : Growing libraries needing comprehensive management and reporting capabilities."
            }
        ],
        priceId:'price_1PM59CRv36BsH0g1vJLxyTcG'
    },
    {
        id:3,
        title:"School (£7/month)",
        description: "loreum ipsum",
        Price:7,
        points:[
            {
                point:"Multi-branch Support"
            },
            {
                point:"Customizable Dashboards"
            },
            {
                point:"API Access for Integration"
            },
            {
                point:"Priority Support"
            },
            {
                point:"Ideal for :  Libraries with multiple branches or complex needs requiring advanced customization and integration."
            }
        ],
        priceId:'price_1PM57IRv36BsH0g1YE6gpnVl'

    },
];

export const Enterprise =[
    {
        id:1,
        title:"Sandard (£199/month)",
        description: "loreum ipsum",
        Price:199,
        points:[
            {
                point:"Catalog Management"
            },
            {
                point:"Member Management (up to 500 members)"
            },
            {
                point:"Check-in/Check-out"
            },
            {
                point:"Basic Reporting"
            },
            {
                point:"Ideal for : Small libraries or startups looking for essential library management tools. "
            }
        ],
        priceId:'price_1PM5ADRv36BsH0g17tHvbB4C'
    },
    {
        id:2,
        title:"Premium (£399/month)",
        description: "loreum ipsum",
        Price:399,
        points:[
            {
                point:"Unlimited Members"
            },
            {
                point:"Advanced Reporting"
            },
            {
                point:"Fine Management"
            },
            {
                point:"RFID Integration"
            },
            {
                point:" Ideal for : Growing libraries needing comprehensive management and reporting capabilities."
            }
        ],
        priceId:'price_1PM5AgRv36BsH0g1oGXDihgZ'

    },
    {
        id:3,
        title:"Advanced (£799/month)",
        description: "loreum ipsum",
        Price:799,
        points:[
            {
                point:"Multi-branch Support"
            },
            {
                point:"Customizable Dashboards"
            },
            {
                point:"API Access for Integration"
            },
            {
                point:"Priority Support"
            },
            {
                point:"Ideal for :  Libraries with multiple branches or complex needs requiring advanced customization and integration."
            }
        ],
        priceId:'price_1PM5CGRv36BsH0g1jJZBPX8J'
    },
];