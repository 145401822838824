import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import TickIcon from '../../../assets/TickIcon.svg';
import PointIcon from '../../../assets/PointIcon.svg';
import CancelIcon from '../../../assets/CancelIcon.svg';
import { Essential, Enterprise } from '../../../constants/SubscriptionPoints';
import { SubscriptionTableData } from '../../../constants/SubscriptionTableData';
import { useNavigate, useLocation } from "react-router-dom";
import './Subscription.scss';
import AuthHandler from '../../Auth/AuthHandler';
import cookieHandler from '../../../cookieHandler';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const countryCode = {
  Essential: '£',
  Enterprise: '£',
};

const Subscription = () => {
  const [selectedPlanToShow, setSelectedPlanToShow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('Essential');
  // const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);
  const [priceId, setPriceId] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();
 
  useEffect(() => {
    (async () => {
      const emailId = cookieHandler.getCookie('email');
      if(emailId){
         await getPriceID(emailId);
      }
    })()
  
  }, []);
  async function getPriceID(emailId){
    // fetch('http://localhost:4000/retrieve-subscription', {
    fetch(`${API_ENDPOINT}/retrieve-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailId: emailId,
      }),
    }).then(async (res) => {
      const { priceId } = await res.json();
      setPriceId(priceId);
    });
    

  }
  async function cancelSubscription(emailId){
    // return await fetch('http://localhost:4000/cancel-subscription', {
    return await fetch(`${API_ENDPOINT}/cancel-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailId: emailId,
      }),
    })
    }

  const handleChoosePlan = (plan) => {
    if (!AuthHandler.isUserLoggedIn()) {
      localStorage.setItem('selectedPlan', JSON.stringify(plan));
      navigate('/signin', { state: { from: location.pathname, selectedPlan: plan } });
    } else {
      setSelectedPlanToShow(plan);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleContinueToPayment = () => {
    setShowModal(false);
    if (selectedPlanToShow) {
      navigate(`/checkout?priceId=${selectedPlanToShow.priceId}&title=${selectedPlanToShow.title}&description=${selectedPlanToShow.description}&price=${selectedPlanToShow.Price}`);
    }
  };

  const handleUpdatePlan = () => {
    navigate('/update-subscription');
  };

  const getPriceDisplay = (price) => {
    return countryCode[selectedPlan] + price;
  };

  const plans = selectedPlan === 'Essential' ? Essential : Enterprise;

  const renderFeatureIcons = (features) => {
    const featureList = [
      'Catalog Management',
      'CRUD operations with Branches, Floors and Zones',
      'Added own Shelves',
      'CRUD operations with Patrons',
    ];

    return featureList.map((feature) => {
      const hasFeature = features.some((item) => item.list === feature);
      return (
        <td key={feature}>
          {hasFeature ? <img src={TickIcon} alt="tick" /> : <img src={CancelIcon} alt="cancel" />}
        </td>
      );
    });
  };

  const handleCancelSubscription = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to cancel the subscription?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it'
    }).then(async (result) => {
      if (result.isConfirmed) {
      const emailId = cookieHandler.getCookie('email');
      await cancelSubscription(emailId)
        Swal.fire(
          'Cancelled!',
          'Your subscription has been cancelled.',
          'success'
        ).then(() => {
           window.location.reload();
        });
      }
    });
  };

  return (
    <Container className="SubscriptionContainer" fluid>
      <Row className="SubscriptionTopContainer">
        <Col xs={9}>
          <h1>Select the plan that best suits your needs.</h1>
        </Col>
        <Col xs={3}>
          <Button className={selectedPlan === 'Essential' ? 'primary' : 'outline-primary'} onClick={() => setSelectedPlan('Essential')}>
            Essential
          </Button>{' '}
          <Button className={selectedPlan === 'Enterprise' ? 'primary' : 'outline-primary'} onClick={() => setSelectedPlan('Enterprise')}>
            Enterprise
          </Button>
        </Col>
      </Row>
      <Row className='Card-row'>
        {plans.map((plan) => (
          <Col key={plan.id} xs={12} sm={6} md={4} lg={3}>
            <div className="Card">
              <div className="TopCard">
                <div>
                  <h4>{plan.title}</h4>
                </div>
                <div>
                  <p>{plan.description}</p>
                </div>
                <div className='CardPrice'>
                  <h2>{getPriceDisplay(plan.Price)} </h2> <p> /month</p>
                </div>
                <div>
                  {priceId === plan.priceId ? (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Button onClick={handleCancelSubscription} style={{backgroundColor: 'gray'}}>Cancel</Button>
                      <Button onClick={handleUpdatePlan}>Update Plan</Button>
                    </div>
                  ) : priceId ? (
                    <Button>Subscribed</Button>
                  ) : (
                    <Button onClick={() => handleChoosePlan(plan)}>Choose this plan</Button>
                  )}
                </div>
              </div>
              <div className="Points">
                {plan.points.map((point, index) => (
                  <div key={index}>
                    <img src={PointIcon} alt="Point" />
                    <p>{point.point}</p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{selectedPlanToShow && selectedPlanToShow.title} <h5>Are you sure you want to choose this plan?</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlanToShow && (
            <>
              <Row className="Popupsubscription">
                <Col className="PopupColumn" xs={12}>
                  <h5>Description:</h5>
                  <p>{selectedPlanToShow.description}</p>
                </Col>
              </Row>
              <Row className="Popupsubscription">
                <Col className="PopupColumn" xs={12}>
                  <h5>Price:</h5>
                  <h1>{getPriceDisplay(selectedPlanToShow.Price)} / mo</h1>
                  {selectedPlanToShow.points.map((popupPoint, index) => (
                    <div key={index} className='ModalPoints'>
                      <div><img src={PointIcon} alt="Point" /></div>
                      <p>{popupPoint.point}</p>
                    </div>
                  ))}
                </Col>
              </Row>
              <div className='ModalButtons'>
                {priceId === selectedPlanToShow.priceId ? (
                  <Button variant="primary" onClick={handleCloseModal}>
                    Close
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleContinueToPayment}>
                    Continue to Payment
                  </Button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Row className="SubscriptionTable">
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Plans</th>
                <th>Basic</th>
                <th>Standard</th>
                <th>Professional</th>
                <th>Enterprise</th>
              </tr>
            </thead>
            <tbody>
              {SubscriptionTableData.map((item) => (
                <tr key={item.id}>
                  <td>{item.Plan}</td>
                  {renderFeatureIcons(item.lists)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Subscription;
