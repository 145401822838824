import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./CookiePolicy.scss"

const CookiePolicy = () => {
  return (
    <Container className="mt-4 cookie-policy">
      <Row>
        <Col>
          <h1>Cookie Policy</h1>
          <p><strong>EFFECTIVE DATE:</strong> 23 OCTOBER 2023</p>
          <p>
            We use cookies to help improve your experience of our website at <a href="https://m2r2.co.uk">https://m2r2.co.uk</a>. This cookie policy is part of M2R2's privacy policy. It covers the use of cookies between your device and our site.
          </p>
          <p>
            We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not cover their cookies.
          </p>
          <p>
            If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from <a href="https://m2r2.co.uk">https://m2r2.co.uk</a>. In such a case, we may be unable to provide you with some of your desired content and services.
          </p>

          <h3>What is a cookie?</h3>
          <p>
            A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the cookie’s purpose, and the lifespan of the cookie itself.
          </p>
          <p>
            Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone, notification preferences), and to personalise your content (e.g. advertising, language).
          </p>
          
          <h3>Types of cookies and how we use them</h3>
          <p><strong>Essential cookies</strong></p>
          <p>
            Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts, and payment processing. We use essential cookies to enable certain functions on our website.
          </p>
          <p><strong>Performance cookies</strong></p>
          <p>
            Performance cookies track how you use a website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users.
          </p>
          <p><strong>Functionality cookies</strong></p>
          <p>
            Functionality cookies are used to collect information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings).
          </p>
          <p><strong>Targeting/advertising cookies</strong></p>
          <p>
            Targeting/advertising cookies help determine what promotional content is most relevant and appropriate to you and your interests.
          </p>

          <h3>How Can You Control Our Website's Use of Cookies?</h3>
          <p>
            You have the right to decide whether to accept or reject cookies on our website. Please note that Essential cookies cannot be rejected as they are strictly necessary to provide you with the services on our website.
          </p>

          <h3>How Often Will We Update This Cookie Policy?</h3>
          <p>
            We may update this Cookie Policy from time to time in order to reflect any changes to the cookies and related technologies we use, or for other operational, legal or regulatory reasons. Each time you use our website, the current version of the Cookie Policy will apply.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CookiePolicy;
