import React, { useState, useRef } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import { motion, useInView } from 'framer-motion';
import { DemovedioData } from '../../../constants/DemovedioData';
import './Demo.scss';

const DemoCard = ({ card }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { margin: '0px 0px -50px 0px' });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: card.id * 0.1 }}
      className="DemovedioCard"
    >
      <Card>
        <motion.img
          src={card.vedio}
          alt={card.title}
          className='CardImage'
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
        <Card.Body>
          <motion.div
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Card.Title className='CardHeading'>{card.title}</Card.Title>
            <Card.Text className='CardDescription'>{card.description}</Card.Text>
          </motion.div>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

const Demo = () => {
  const [visibleCards, setVisibleCards] = useState(9); 

  const handleSeeMore = () => {
    setVisibleCards(DemovedioData.length);
  };

  return (
    <Container fluid className="Container">
      <motion.h1
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        SARA Library Management System Demo Videos
      </motion.h1>
      <Row className='DemovedioCardContainer'>
        {DemovedioData.slice(0, visibleCards).map((card) => (
          <Col key={card.id} xs={12} sm={6} md={4} lg={4} xl={4} className="mb-4">
            <DemoCard card={card} />
          </Col>
        ))}
      </Row>
      {visibleCards < DemovedioData.length && (
        <div className="text-center mt-3">
          <Button onClick={handleSeeMore} variant="primary">See More</Button>
        </div>
      )}
    </Container>
  );
};

export default Demo;
