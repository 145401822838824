import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import TickIcon from '../../../../assets/TickIcon.svg';
import PointIcon from '../../../../assets/PointIcon.svg';
import CancelIcon from '../../../../assets/CancelIcon.svg';
import { Essential, Enterprise } from '../../../../constants/SubscriptionPoints';
import { SubscriptionTableData } from '../../../../constants/SubscriptionTableData';
import { useNavigate, useLocation } from "react-router-dom";
import './UpdateSubscription.scss';
import AuthHandler from '../../../Auth/AuthHandler';
import cookieHandler from '../../../../cookieHandler';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const countryCode = {
  Essential: '£',
  Enterprise: '£',
};

const UpdateSubscription = () => {
  const [selectedPlanToShow, setSelectedPlanToShow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('Essential');
  const [currentPriceId, setCurrentPriceId] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ( () => {
      const emailId = cookieHandler.getCookie('email');
      if (emailId) {
        setEmailId(emailId)
        getPriceID(emailId);
      }
    })();
  }, []);

  async function getPriceID(emailId) {
    // const res = await fetch('http://localhost:4000/retrieve-subscription', {
    const res = await fetch(`${API_ENDPOINT}/retrieve-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailId: emailId,
      }),
    });
    const { priceId } = await res.json();
    setCurrentPriceId(priceId);
  }

  const handleChoosePlan = (plan) => {
    if (!AuthHandler.isUserLoggedIn()) {
      localStorage.setItem('selectedPlan', JSON.stringify(plan));
      navigate('/signin', { state: { from: location.pathname, selectedPlan: plan } });
    } else {
      setSelectedPlanToShow(plan);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateSubscription = async () => {
    setShowModal(false);
    if (selectedPlanToShow && currentPriceId) {
      try {
        // const response = await fetch('http://localhost:4000/update-subscription', {
        const response = await fetch(`${API_ENDPOINT}/update-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            emailId: emailId,
            newPriceId: selectedPlanToShow.priceId,
          }),
        });

        if (!response.ok) throw new Error('Subscription update failed');
        navigate(`/checkout?priceId=${selectedPlanToShow.newPriceId}&title=${selectedPlanToShow.title}&description=${selectedPlanToShow.description}&price=${selectedPlanToShow.Price}`);

        await response.json();
        Swal.fire('Success', 'Your subscription has been updated.', 'success');
        navigate('/subscription');
      } catch (error) {
        Swal.fire('Error', error.message || 'Subscription update failed.', 'error');
      }
    }
  };

  // const handleContinueToPayment = () => {
  //   setShowModal(false);
  //   if (selectedPlanToShow) {
  //     navigate(`/checkout?priceId=${selectedPlanToShow.priceId}&title=${selectedPlanToShow.title}&description=${selectedPlanToShow.description}&price=${selectedPlanToShow.Price}`);
  //   }
  // };

  const getPriceDisplay = (price) => {
    return countryCode[selectedPlan] + price;
  };

  const plans = selectedPlan === 'Essential' ? Essential : Enterprise;

  const renderFeatureIcons = (features) => {
    const featureList = [
      'Catalog Management',
      'CRUD operations with Branches, Floors and Zones',
      'Added own Shelves',
      'CRUD operations with Patrons',
    ];

    return featureList.map((feature) => {
      const hasFeature = features.some((item) => item.list === feature);
      return (
        <td key={feature}>
          {hasFeature ? <img src={TickIcon} alt="tick" /> : <img src={CancelIcon} alt="cancel" />}
        </td>
      );
    });
  };

  return (
    <Container className="UpdateSubscriptionContainer" fluid>
      <Row className="SubscriptionTopContainer">
        <Col xs={9}>
          <h1>Select the plan that best suits your needs.</h1>
        </Col>
        <Col xs={3}>
          <Button className={selectedPlan === 'Essential' ? 'primary' : 'outline-primary'} onClick={() => setSelectedPlan('Essential')}>
            Essential
          </Button>{' '}
          <Button className={selectedPlan === 'Enterprise' ? 'primary' : 'outline-primary'} onClick={() => setSelectedPlan('Enterprise')}>
            Enterprise
          </Button>
        </Col>
      </Row>
      <Row className='Card-row'>
        {plans.map((plan) => (
          <Col key={plan.id} xs={12} sm={6} md={4} lg={3}>
            <div className="Card">
              <div className="TopCard">
                <div>
                  {currentPriceId === plan.priceId ? "" : <h5 style={{ color: "#624bff", fontWeight: "bold" }}>UPGRADE TO</h5>}
                  <h4>{plan.title}</h4>
                </div>
                <div>
                  <p>{plan.description}</p>
                </div>
                <div className='CardPrice'>
                  <h2>{getPriceDisplay(plan.Price)}</h2> <p> /month</p>
                </div>
                <div>
                  {currentPriceId === plan.priceId ?
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h5 style={{ margin: "20px 15px 0px 15px", backgroundColor: "green", padding: "10px 5px", color: "white" }}>Current subscription</h5>
                    </div>
                    :
                    <Button onClick={() => handleChoosePlan(plan)}>Update this plan</Button>
                  }
                </div>
              </div>
              <div className="Points">
                {plan.points.map((point, index) => (
                  <div key={index}>
                    <img src={PointIcon} alt="Point" />
                    <p>{point.point}</p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{selectedPlanToShow && selectedPlanToShow.title} <h5>Are you sure you want to update to this plan?</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlanToShow && (
            <>
              <Row className="Popupsubscription">
                <Col className="PopupColumn" xs={12}>
                  <h5>Description:</h5>
                  <p>{selectedPlanToShow.description}</p>
                </Col>
              </Row>
              <Row className="Popupsubscription">
                <Col className="PopupColumn" xs={12}>
                  <h5>Price:</h5>
                  <h1>{getPriceDisplay(selectedPlanToShow.Price)} / mo</h1>
                  {selectedPlanToShow.points.map((popupPoint, index) => (
                    <div key={index} className='ModalPoints'>
                      <div><img src={PointIcon} alt="Point" /></div>
                      <p>{popupPoint.point}</p>
                    </div>
                  ))}
                </Col>
              </Row>
              <div className='ModalButtons'>
                <Button variant="primary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleUpdateSubscription}>
                  Update this plan
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Row className="SubscriptionTable">
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Plans</th>
                <th>Basic</th>
                <th>Standard</th>
                <th>Professional</th>
                <th>Enterprise</th>
              </tr>
            </thead>
            <tbody>
              {SubscriptionTableData.map((item) => (
                <tr key={item.id}>
                  <td>{item.Plan}</td>
                  {renderFeatureIcons(item.lists)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateSubscription;
