export const faqData = [
    {
      question: 'What is SARA Library Management System?',
      answer: 'The SARA Library Management System is a comprehensive software solution designed specifically for libraries to efficiently manage their operations. It includes features such as cataloging, circulation, patron management, reporting, and more, aimed at streamlining library workflows.',
    },
    {
      question: 'What are the key features of SARA Library Management System?',
      answer: 'SARA LMS offers a range of features including cataloging and indexing of library materials, circulation management for lending and returning items, patron management for user registrations and profiles, reporting and analytics tools, and integration capabilities with other library systems.',
    },
    {
      question: 'How flexible is SARA Library Management System for adapting to specific library needs?',
      answer: 'SARA LMS is highly adaptable and configurable to accommodate the unique requirements of different libraries. It allows administrators to customize settings, workflows, and interfaces, ensuring a tailored solution that optimizes efficiency and usability based on individual library preferences.',
    },
    {
      question: 'How does SARA Library Management System handle security and privacy of library data?',
      answer: 'SARA LMS prioritizes the security and privacy of library data. It employs robust security measures such as user authentication, data encryption, access controls, and regular backups to protect library information from unauthorized access, loss, or breaches.',
    },
    {
      question: 'What kind of technical support is available for SARA Library Management System?',
      answer: 'SARA LMS comes with comprehensive technical support for users. This typically includes initial setup assistance, training sessions for library staff, ongoing customer support for issue resolution, and software updates to ensure optimal performance and usability.',
    },
  ];