import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { motion, useInView } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import { ModulePoints } from '../../../constants/ModulePoints';
import './Module.scss';
import ModulesImg from '../../../assets/ModulesImg.svg';
import { animateScroll as scroll } from 'react-scroll';

const ModulePoint = ({ id, icon, title, description }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { margin: '0px 0px -50px 0px' });

  return (
    <motion.div
      key={id}
      className="Module-point"
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: id * 0.1 }}
    >
      <div className="icon">{icon}</div>
      <div className="TitleandDescription">
        <motion.h5
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {title}
        </motion.h5>
        <motion.p
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          {description}
        </motion.p>
      </div>
    </motion.div>
  );
};

const Module = () => {
  const moduleRef = useRef(null);

  useEffect(() => {
    scroll.scrollTo(moduleRef.current.offsetTop, {
      smooth: true,
      duration: 500,
    });
  }, []);

  return (
    <Container fluid className="Container">
      <motion.h1
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Modules of SARA Library Management System
      </motion.h1>
      <Row className="ModulesContainer">
        <Col xs={12} md={6} className="ModuleRight">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={ModulesImg} alt="Modules" style={{ maxWidth: '100%' }} />
          </motion.div>
        </Col>
        <Col xs={12} md={6} className="ModuleLeft">
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="ModuleHeading">
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                These modules help in the smooth functioning of Library Management software
              </motion.h3>
            </div>
          </motion.div>
        </Col>
      </Row>
      <Row className="ModulePoints" ref={moduleRef}>
        <Col xl={10} className="ModulePointsColumn">
          <div className="ModulePoints">
            {ModulePoints.map(({ id, icon, title, description }) => (
              <ModulePoint
                key={id}
                id={id}
                icon={icon}
                title={title}
                description={description}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Module;
