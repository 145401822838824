import CookieHandler from '../../cookieHandler';

class AuthHandler {
  constructor() {
    if (!AuthHandler.instance) {
      AuthHandler.instance = this;
    }

    return AuthHandler.instance;
  }

  isUserLoggedIn() {
    const cookieName = 'token';
    const token = CookieHandler.getCookie(cookieName);
    return token ? true : false;
  }

  logOutUser() {
    const cookieName = 'token';
    CookieHandler.deleteCookie(cookieName);
    window.location.href = '/';
  }

  clearSubscriptions() {
    localStorage.clear();
  }

}

const instance = new AuthHandler();
Object.freeze(instance);

export default instance;
