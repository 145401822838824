import Cloudhost from '../assets/Cloudhost.png'
import AiPowered from '../assets/AiPowered.png'
import Future from '../assets/Future.png'

export const AboutusCardsData = [
    {
        id:1,
        image:Cloudhost,
        title:"Cloud-Hosted for Unmatched Accessibility",
        description:"SARA is a cloud-native solution, ensuring libraries have secure, scalable, and reliable access to their management system from anywhere in the world. Our cloud-hosted infrastructure provides the flexibility and resilience necessary for libraries to adapt to changing needs and user demands, without the burden of managing physical servers or data storage solutions."   
    },
    {
        id:2,
        image:AiPowered,
        title:"AI-Powered for Enhanced Library Experiences",
        description:"Leveraging the latest in machine learning and artificial intelligence, SARA transforms the library experience for users and administrators alike. Our AI-powered features include personalised recommendations, intelligent search capabilities, and automated cataloguing, significantly reducing manual effort and enhancing user engagement. By analysing user interactions and preferences, SARA offers insights and suggestions that enrich the discovery process, making every visit to the library a unique and rewarding experience."   
    },
    {
        id:3,
        image:Future,
        title:"Embrace the Future with SARA",
        description:"SARA is not just a library management system; it's a gateway to the future of library services. With its modular design, cloud-hosted flexibility, and AI-powered features, SARA is equipped to handle the complexities of modern library management while providing an intuitive, user-friendly experience. Whether it's managing resources, engaging with patrons, or analysing data for strategic decisions, SARA stands ready to elevate your library to new heights. Welcome to the next generation of library management—welcome to SARA."   
    },

]