import DemoImg from '../assets/DemoImg.png'

export const DemovedioData = [
    {
        id:1,
        vedio:DemoImg,
        title:"User Login",
        description:"How user will login and make use of Dashbboard"
    },
    {
        id:2,
        vedio:DemoImg,
        title:"Branches Management",
        description:"CRUD operation in Branch Management"
    },
    {
        id:3,
        vedio:DemoImg,
        title:"Floors Management",
        description:"CRUD operation in Floors Management"
    },
    {
        id:4,
        vedio:DemoImg,
        title:"Zones Management",
        description:"CRUD operation in Zones Management"
    },
    {
        id:5,
        vedio:DemoImg,
        title:"Books Management",
        description:"CRUD operation in Books Management"
    },
    {
        id:6,
        vedio:DemoImg,
        title:"Items Management",
        description:"CRUD operation in Items Management"
    },
    {
        id:7,
        vedio:DemoImg,
        title:"Patrons Management",
        description:"CRUD operation in Patrons Management"
    },
    {
        id:8,
        vedio:DemoImg,
        title:"Circulations",
        description:"CRUD operation in Circulations"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
    {
        id:9,
        vedio:DemoImg,
        title:"Issue Books",
        description:"CRUD operation in Issue Books"
    },
]