import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card } from 'react-bootstrap';
import Aboutusimg from '../../../assets/Aboutusimg.png';
import LMSSupport from '../../../assets/LMSSupport.png';
import { Aboutuspoints } from '../../../constants/Aboutuspoints';
import { AboutusCardsData } from '../../../constants/AboutusCardsData';
import { BenefitsData } from '../../../constants/BenefitsData';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Aboutus.scss';

const Aboutus = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container fluid className="Container">
      <Row className='AboutusContainer'>
        <Col xl={8} className='AboutusTextColumn'>
          <div className='AboutusLeftText' data-aos="fade-right">
            <h2 data-aos="fade-up">Introducing SARA: The Future of Library Management</h2>
            <div className='AboutusPoints' data-aos="fade-up" data-aos-delay="200">
              {Aboutuspoints.map(({ id, icon, point }) => (
                <div key={id} className="Aboutus-point" data-aos="fade-right" data-aos-delay={`${100 * id}`}>
                  <div className="icon">{icon}</div>
                  <div className="point">{point}</div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col xl={4} className="AboutusImage">
          <img
            src={Aboutusimg}
            alt="LMS"
            className="Aboutus"
            width={400}
            data-aos="fade-left"
          />
        </Col>
      </Row>
      <Row className='AboutusCards'>
        {AboutusCardsData.map((card) => (
          <Col key={card.id} xs={12} sm={6} md={4} lg={4} xl={4} data-aos="fade-up" data-aos-delay={`${card.id * 100}`}>
            <div className='CustomCard'>
              <Card>
                <img src={card.image} alt={card.title} className='CardImage' />
                <Card.Body>
                  <div>
                    <Card.Title className='CardHeading'>{card.title}</Card.Title>
                    <Card.Text className='CardDescription'>{card.description}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      <Row className='BenefitsContainer'>
        <Col xs={12}>
          <h1 data-aos="fade-up">Benefits of SARA LMS</h1>
        </Col>
        {BenefitsData.map((benefit) => (
          <Col key={benefit.id} xs={12} sm={6} md={4}>
            <div className='Benefits' data-aos="fade-up" data-aos-delay={`${benefit.id * 100}`}>
              <div className='Icon'>{benefit.icon}</div>
              <div className='Title'>
                <h4>{benefit.title}</h4>
              </div>
              <div className='Description'>
                <p>{benefit.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row className='SupportContainer'>
        <Col xs={12} sm={8} md={8} className='LeftContainer'>
          <div data-aos="fade-up">
            <h3>A dedicated team to support you</h3>
            <p>
              Our dedicated team at SARA LMS is committed to providing exceptional support throughout your library management system journey. From setup to ongoing maintenance, we're here to ensure your success and satisfaction with our software.
            </p>
            <a
              className="btn contact-us-btn"
              href="/contactus"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Contact Us
            </a>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} className='RightContainer'>
          <div data-aos="fade-left">
            <img src={LMSSupport} alt="LMS Support" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Aboutus;
