import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ForgotPassword.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOTP] = useState('');

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    console.log('Sending OTP to:', email);
    setShowOTPInput(true);
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    console.log('Verifying OTP:', otp);
    console.log('OTP Verified!');
  };

  return (
    <Container className="ForgotPasswordContainer">
      <Row>
        <Col xs={12} className="ForgotPasswordHeader">
          <h2>Forgot Password</h2>
          <p>Enter your email to receive a one-time password (OTP)</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div className="forgot-password-form">
            {!showOTPInput ? (
              <Form onSubmit={handleEmailSubmit}>
                <Form.Group controlId="formBasicEmail" className="form-email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="send-otp-button">
                  Send OTP
                </Button>
              </Form>
            ) : (
              <Form onSubmit={handleOTPSubmit}>
                <Form.Group controlId="formBasicOTP" className="form-otp">
                  <Form.Label>Enter OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="verify-otp-button">
                  Verify OTP
                </Button>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
