import React, { useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import './RequestDemo.scss';
import CancelIcon from '../../../assets/CancelIcon.svg';

const RequestDemo = ({ onCancelRequest }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    comments: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: '',
      email: '',
      mobile: '',
      comments: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCancel = () => {
    onCancelRequest(); 
  };

  return (
    <Container className="demo-container" fluid>
      <div className="inner-container">
        <div className='Topheader'> 
        <div className='TopheaderText'>
            <h4>Request a Demo</h4>
          <h6>Or Call us : +91-9976265438</h6>
        </div>
        <div className='CancelIcon'>
          <img src={CancelIcon} alt="Cancel" className="cancel-icon" onClick={handleCancel} />
        </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter your name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="mobile">
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="Enter your mobile number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="comments" className='Comments'>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="comments"
                  value={formData.comments}
                  onChange={handleInputChange}
                  placeholder="Enter your comments"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default RequestDemo;
