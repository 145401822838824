import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import SARAlogo from '../../assets/SARAlogo.svg';
import './Header.scss';
import AuthHandler from "../Auth/AuthHandler";
import cookieHandler from '../../cookieHandler.js'

const CustomNavbar = () => {
  const [currentLink, setCurrentLink] = useState('');

  const handleNavLinkClick = (link) => {
    setCurrentLink(link);
  };

  const logOutUser = () => {
    cookieHandler.deleteCookie('email');
    AuthHandler.logOutUser();
    AuthHandler.clearSubscriptions();
    window.location.reload();
  };

  const getLinkClass = (link) => {
    return currentLink === link ? 'nav-link active' : 'nav-link';
  };

  return (
    <Navbar expand="lg" className="custom-navbar" style={{ backgroundColor: '#ffffff' }}>
      <Navbar.Brand href="/" className="align-left">
        <img src={SARAlogo} alt="" width={150} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="mr-auto">
          <NavLink
            to="/"
            exact
            className={getLinkClass('/')}
            onClick={() => handleNavLinkClick('/')}
          >
            Home
          </NavLink>
          <NavLink
            to="/aboutus"
            className={getLinkClass('/aboutus')}
            onClick={() => handleNavLinkClick('/aboutus')}
          >
            About us
          </NavLink>
          <NavLink
            to="/module"
            className={getLinkClass('/module')}
            onClick={() => handleNavLinkClick('/module')}
          >
            Module
          </NavLink>
          <NavLink
            to="/subscription"
            className={getLinkClass('/subscription')}
            onClick={() => handleNavLinkClick('/subscription')}
          >
            Pricing
          </NavLink>
          <NavLink
            to="/demo"
            className={getLinkClass('/demo')}
            onClick={() => handleNavLinkClick('/demo')}
          >
            Demo
          </NavLink>
          <NavLink
            to="/blog"
            className={getLinkClass('/blog')}
            onClick={() => handleNavLinkClick('/blog')}
          >
            Blog
          </NavLink>
          <NavLink
            to="/contactus"
            className={getLinkClass('/contactus')}
            onClick={() => handleNavLinkClick('/contactus')}
          >
            Contact us
          </NavLink>
        </Nav>
        { AuthHandler.isUserLoggedIn() ?  <Button className="sign-in-btn" onClick={logOutUser}>Logout</Button> :  <Button className="sign-in-btn" href='/signin'>Sign In</Button>}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
