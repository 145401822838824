import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./TermsOfUse.scss";

const TermsOfUse = () => {
  return (
    <Container className="mt-4 terms-of-service">
      <Row>
        <Col>
          <h1>Terms of Service</h1>
          <p>
            These Terms of Service govern your use of the website located at <a href="https://m2r2.co.uk">https://m2r2.co.uk</a> and any related services provided by M2R2.
          </p>
          <p>
            By accessing <a href="https://m2r2.co.uk">https://m2r2.co.uk</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by M2R2.
          </p>
          <p>
            We, M2R2, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication. These Terms of Service were last updated on 25 October 2023.
          </p>

          <h3>Description of Service</h3>
          <p>
            M2R2 is a premier brand in the high-tech engineering consultancy industry, emphasising sectors such as Aerospace, Automotive, Rail, Energy, Robotics, Future Mobility, and Information Technology. We commit to providing an engineering-driven, client-focused approach, aiming for optimal cost efficiency. M2R2 proffers a spectrum of services tailored to the expansive needs of the engineering and retail industries. While we offer standardised service templates, we are amenable to personalising our services to cater to unique client specifications.
          </p>

          <h3>Limitations of Use</h3>
          <p>
            By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
          </p>
          <ul>
            <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
            <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server;</li>
            <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service M2R2 provides;</li>
            <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
            <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
            <li>use this website in conjunction with sending unauthorised advertising or spam;</li>
            <li>harvest, collect, or gather user data without the user’s consent; or</li>
            <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
          </ul>

          <h3>Intellectual Property</h3>
          <p>
            The intellectual property in the materials contained in this website are owned by or licensed to M2R2 and are protected by applicable copyright and trademark law. All software, applications, and innovations developed by M2R2 remain the exclusive property of M2R2 unless otherwise agreed upon in writing. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use. This constitutes the grant of a licence, not a transfer of title. This licence shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by M2R2 at any time.
          </p>

          <h3>Recruitment Approach</h3>
          <p>
            Our recruitment methodology capitalises on our associations with the workforces of esteemed OEMs. We often identify and acquire talent before they become available in the open market. Furthermore, our recruitment services are contingent upon the successful onboarding of the preferred candidate, thereby minimising risks for our clients.
          </p>

          <h3>Service Provisions</h3>
          <p>
            Clients can anticipate a comprehensive service experience, from cost optimisation of product design/production lines to the development and certification of engineering products. We also address challenges related to team size variations, offering both augmentation and reduction solutions, always aiming to mitigate client risk concerning time and finances.
          </p>

          <h3>Charges and Payments</h3>
          <p>
            We uphold a principled stance on service charges. Certain services are offered on a post-delivery payment structure, ensuring clients are billed only after achieving satisfaction with our service delivery.
          </p>

          <h3>Liability</h3>
          <p>
            Our website and the materials on our website are provided on an ‘as is’ basis. To the extent permitted by law, M2R2 makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights. In no event shall M2R2 or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if M2R2 or an authorised representative has been notified, orally or in writing, of the possibility of such damage.
          </p>
          <p>
            Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. While M2R2 endeavours to provide flawless service execution, any service disruptions or failures should not be attributed as negligence on our part. We disclaim liabilities exceeding the service contract’s stipulated value.
          </p>

          <h3>Accuracy of Materials</h3>
          <p>
            The materials appearing on our website are not comprehensive and are for general information purposes only. M2R2 does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. You should not rely solely on the said materials to make decisions, we advise you to consult other, more complete sources of information. You are solely responsible for the strict reliance on the materials posted on our website. We have the right to change the information on our website without prior notice and users are responsible to monitor said changes. Any historical information present on our website is provided for reference only.
          </p>

          <h3>Additional Tools</h3>
          <p>
            Please note that our website may include third-party tools which are outside of our control. The access to said tools is provided on an ‘as is’ basis with no warranties, expressed or implied, and without any endorsement. We are not liable for the use of the third-party tools provided on our website. You are solely responsible for any risks associated with using said tools, we recommend reading the relevant third-party provider’s Terms of Service. M2R2 has the right to offer new third-party tools in the future.
          </p>

          <h3>Links</h3>
          <p>
            M2R2 has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval or control by M2R2 of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.
          </p>

          <h3>Inaccuracies and Mistakes</h3>
          <p>
            Please note that any content available on our website may contain typographical errors, inaccuracies, or mistakes related to the service’s description and availability. We have the right to cancel or update any existing services should any information provided by us be proven inaccurate. Said information can be amended at any time without prior notice. Please note that we are not obligated to clarify any of the information related to our website and service.
          </p>

          <h3>Indemnification</h3>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of your use of the website or breach of these Terms of Service.
          </p>

          <h3>Right to Terminate</h3>
          <p>
            We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.
          </p>

          <h3>Severance</h3>
          <p>
            Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.
          </p>

          <h3>Entire Agreement</h3>
          <p>
            These Terms of Service constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Service shall not operate as a waiver of such right or provision. These Terms of Service operate to the fullest extent permissible by law.
          </p>

          <h3>Governing Law</h3>
          <p>
            These Terms of Service are governed by and construed in accordance with the laws of the United Kingdom. You irrevocably submit to the exclusive jurisdiction of the courts in that location.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUse;
