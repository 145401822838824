import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Signin.scss';
import CookieHandler from '../../../cookieHandler';

const Signin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  const initializeGoogleSignIn = () => {
    window.google.accounts.id.initialize({
      client_id: '288160416705-o8c745rmklqvtqkrt9bmrgmk7hujlq0t.apps.googleusercontent.com',
      callback: handleGoogleResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      { theme: 'outline', size: 'large' }
    );
  };

  const handleGoogleResponse = (response) => {
    console.log('Encoded JWT ID token:', response.credential);

    fetch('google-signinapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { token, refreshToken, expiresIn, refreshExpiresIn, accountNumber, accountName, roles, firstName, lastName } = data;
        CookieHandler.setTokens({
          token,
          expiresIn,
          refreshToken,
          refreshExpiresIn,
          accountNumber,
          accountName,
          roles,
          firstName,
          lastName,
        });
        console.log('Google login successful! Auth Token:', token);

        Swal.fire({
          icon: 'success',
          title: 'Login Successful!',
          text: 'Redirecting to subscription...',
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          navigate('/subscription');
        });
      })
      .catch((error) => {
        console.error('Google sign-in error:', error.message);

        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: error.message,
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const bearertoken = 'FV14DwYx81XpuakIEncdTAUz3O5Y16qP';
      const response = await fetch('https://sara-api.m2r2.co.uk/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearertoken}`,
        },
        credentials: 'include',
        body: JSON.stringify({
          username: formData.username,
          password: formData.password,
        }),
      });

      if (!response.ok) {
        throw new Error('Sign in request failed');
      }

      const { token, refreshToken, expiresIn, refreshExpiresIn, accountNumber, accountName, email, roles, firstName, lastName } = await response.json();
      CookieHandler.setTokens({
        token,
        expiresIn,
        refreshToken,
        refreshExpiresIn,
        accountNumber,
        accountName,
        email,
        roles,
        firstName,
        lastName,
      });
      console.log('Login successful! Auth Token:', token);

      Swal.fire({
        icon: 'success',
        title: 'Login Successful!',
        text: 'Redirecting to subscription...',
        timer: 2000,
        showConfirmButton: false,
      }).then(() => {
        const storedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
        const redirectPath = storedPlan ? 
          `/checkout?priceId=${storedPlan.priceId}&title=${encodeURIComponent(storedPlan.title)}&description=${encodeURIComponent(storedPlan.description)}&price=${encodeURIComponent(storedPlan.Price)}` 
          : '/subscription';
        navigate(redirectPath);
      });
    } catch (error) {
      console.error('Sign in error:', error.message);

      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: error.message,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Container className="SigninContainer">
      <Row>
        <Col xs={12} className="SigninHeader">
          <h2>Sign In to your M2R2 Customer Account</h2>
          <p>To proceed with your order, kindly log in to your existing M2R2 Customer Account or register a new one.</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div className="signin-form">
            <Form onSubmit={handleSignIn}>
              <Form.Group controlId="formBasicUsername">
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  placeholder="Username"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="position-relative">
                <div className="password-input-container">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </Form.Group>

              <Button variant="link" className="forgot-password-link" href="/forgotpassword">
                Forgot Password?
              </Button>
              <br />
              <Button variant="primary" type="submit" className="signin-button">
                Sign In
              </Button>
            </Form>

            <div className="or-separator text-center">
              <span className="or-text">or</span>
            </div>

            <div className="text-center">
              <div id="google-signin-button" className="google-signin-button"></div>
            </div>
            <div className="signup-link">
              <p>
                Not an M2R2 Customer yet? <a href="/signup">Register</a>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Signin;
